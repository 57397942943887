@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

.inria-sans-light {
  font-family: "Inria Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.inria-sans-regular {
  font-family: "Inria Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.inria-sans-bold {
  font-family: "Inria Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.inria-sans-light-italic {
  font-family: "Inria Sans", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.inria-sans-regular-italic {
  font-family: "Inria Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.inria-sans-bold-italic {
  font-family: "Inria Sans", sans-serif;
  font-weight: 700;
  font-style: italic;
}

body {
  width: auto;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
